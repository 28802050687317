/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment } from "react";
import { graphql } from "gatsby";
import { parseISO, isAfter, startOfYesterday } from "date-fns";
import groupBy from "lodash/groupBy";
import queryString from "query-string";

import {
  Layout,
  Link,
  PageHeading,
  ViewingRoomsList,
  ViewingRoomsVerticalList,
} from "components";

function ViewingRoomsPage({
  data: {
    allSanityViewingRoom: { viewingRooms },
  },
  location: { search },
}) {
  const yesterday = startOfYesterday();

  const { upcomingViewingRooms = [], pastViewingRooms = [] } = groupBy(
    viewingRooms.map(({ node }) => node),
    ({ start_date, end_date }) => {
      const isAfterYesterday =
        (end_date && isAfter(parseISO(end_date), yesterday)) ||
        isAfter(parseISO(start_date), yesterday);
      return isAfterYesterday ? "upcomingViewingRooms" : "pastViewingRooms";
    }
  );

  const displayPastViewingRooms =
    Object.prototype.hasOwnProperty.call(queryString.parse(search), "past") &&
    pastViewingRooms.length > 0;

  const viewingRoomsByYear = groupBy(
    displayPastViewingRooms ? pastViewingRooms : upcomingViewingRooms,
    "year"
  );

  const years = Object.keys(viewingRoomsByYear).sort((a, b) => b - a);

  return (
    <Layout pageTitle="Viewing Rooms">
      <PageHeading heading="Viewing Rooms">
        {pastViewingRooms.length > 0 && (
          <Fragment>
            <Link
              to="/viewing-rooms"
              variant="links.primary"
              sx={{
                textDecoration: !displayPastViewingRooms ? "underline" : "none",
                fontWeight: !displayPastViewingRooms ? "700" : "normal",
              }}
            >
              current / upcoming
            </Link>

            <div
              sx={{
                display: "inline-block",
                px: "xs",
              }}
            >
              -
            </div>

            <Link
              to="/viewing-rooms?past"
              variant="links.primary"
              sx={{
                textDecoration: displayPastViewingRooms ? "underline" : "none",
                fontWeight: displayPastViewingRooms ? "700" : "normal",
              }}
            >
              past
            </Link>
          </Fragment>
        )}
      </PageHeading>

      {!displayPastViewingRooms && upcomingViewingRooms.length === 0 && (
        <p>There are currently no current or upcoming viewing rooms</p>
      )}

      {!displayPastViewingRooms &&
        years.map((year) => (
          <ViewingRoomsVerticalList
            key={year}
            id={year}
            heading={displayPastViewingRooms ? year : null}
            viewingRooms={viewingRoomsByYear[year].reverse()}
            sx={{ mb: "xl" }}
          />
        ))}

      {displayPastViewingRooms &&
        years.map((year) => (
          <ViewingRoomsList
            key={year}
            id={year}
            heading={displayPastViewingRooms ? year : null}
            viewingRooms={viewingRoomsByYear[year]}
            sx={{ mb: "xl" }}
          />
        ))}
    </Layout>
  );
}

export const query = graphql`
  query {
    allSanityViewingRoom(
      filter: { start_date: { ne: null } }
      sort: { fields: start_date, order: DESC }
    ) {
      viewingRooms: edges {
        node {
          _id
          title
          year: start_date(formatString: "YYYY")
          start_date
          start_date_string: start_date(formatString: "Do MMMM YYYY")
          end_date
          end_date_string: end_date(formatString: "Do MMMM YYYY")
          content: _rawContent(resolveReferences: { maxDepth: 10 })

          artworks {
            artist {
              create_web_page
              first_name
              last_name
              yearOfDeath: date_of_death(formatString: "yyyy")
              yearOfBirth: date_of_birth(formatString: "yyyy")
            }
            is_archived
            name
            work_title
            work_date
            work_image {
              asset {
                gatsbyImageData(height: 220, width: 290)
              }
            }
            work_image__large: work_image {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 440)
              }
            }
          }
        }
      }
    }
  }
`;

export default ViewingRoomsPage;
